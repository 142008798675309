import React from "react";
import { compose } from "recompose";
import Loadable from "theme/components/helpers/Loadable";
import { withHideOnErrorBoundary } from "theme/components/helpers/ErrorBoundary";
import Wysiwyg from "theme/modules/WysiwygV2";
import shortcodes from "theme/modules/Wysiwyg/MagentoWysiwyg/shortcodes";
import transforms from "theme/modules/Wysiwyg/MagentoWysiwyg/transforms";
import EnhanceWysiwyg from "theme/modules/Wysiwyg/EnhanceWysiwyg";
import classnames from "classnames";
import StorefrontContent from "theme/modules/StorefrontContent";

const LegacyCmsBlock = EnhanceWysiwyg({ shortcodes, transforms })((props) => (
  <StorefrontContent type="CmsBlock" id={props.identifier}>
    <div className={classnames(props.className, props.identifier)}>
      {props.children}
    </div>
  </StorefrontContent>
));

const CmsBlock = (props) => {
  if (props.cmsBlock) {
    return <Wysiwyg blockIdentifier={props.cmsBlock.identifier} heroEffect={props.cmsBlock.kpl_hero_banner_effect} content={props.cmsBlock.contentWysiwyg} />;
  } else if (props.contentWysiwyg) {
    if (process.env.NODE_ENV === "development") {
      console.warn(
        `The CmsBlock component should now take a 'cmsBlock' property containing the data described by a 'CmsBlockFragment'. The usage of a contentWysiwyg property will be removed in v3.0.0.`
      );
    }
    return <Wysiwyg content={props.contentWysiwyg} />;
  } else if (props.content) {
    if (process.env.NODE_ENV === "development") {
      console.warn(
        `The CmsBlock component should now take a 'cmsBlock' property containing the data described by a 'CmsBlockFragment'. The usage of a contentWysiwyg property will be removed in v3.0.0.`
      );
    }
    return <LegacyCmsBlock content={props.content} />;
  } else {
    if (process.env.NODE_ENV === "development") {
      console.error(
        "No cmsBlock property was passed to the <CmsBlock /> component."
      );
    }
    return null;
  }
};

export default compose(
  withHideOnErrorBoundary,
  Loadable((props) => !props.loading)
)(CmsBlock);
