import React from "react"
import PropTypes from "prop-types"
import CmsBlock from "theme/modules/CmsBlock"
import {useQuery} from "react-apollo"
import SmartCmsBlockQuery from "./SmartCmsBlockQuery.gql"

const SmartCmsBlock = (props) => {
  const {identifier} = props
  const {data, loading} = useQuery(SmartCmsBlockQuery, {variables: {identifier}})

  if( loading ) return null
  if( !data?.cmsBlock?.length ) return null
  return <CmsBlock cmsBlock={data?.cmsBlock[0]} />
}

SmartCmsBlock.propTypes = {
  identifier: PropTypes.string.isRequired,
}

export default SmartCmsBlock